import $ from "jquery";
$(() => {
    $.get('https://www.cloudflare.com/cdn-cgi/trace', function (d) {
        if (d == null || d === "\n" || d === "") {
            return;
        }
        var data = d.replace(/[\r\n]+/g, '","').replace(/\=+/g, '":"');
        data = '{"' + data.slice(0, data.lastIndexOf('","')) + '"}';
        var jsondata = JSON.parse(data);
        window.localStorage.setItem("CIP", jsondata.ip);
        window.localStorage.setItem("uag", jsondata.uag);
    });
});
